<template lang='pug'>
div.form__wrapper
  img.logo(src='../assets/logo.png')
  h1 Reset Password
  el-form(v-if='!submitted', :model='form', :rules='rules', ref='resetPasswordForm', 
          v-loading='loading', @keyup.enter.native='resetPassword')
    div.double-form-item
      el-form-item(prop='password')
        el-input(v-model='form.password', type='password', placeholder='New password', autofocus)
      el-form-item(prop='password_confirmation')
        el-input(v-model='form.password_confirmation', type='password', placeholder='Confirm')
    el-form-item
      el-button.submit(type='primary', @click='resetPassword') Save my new password
  div(v-else) 
    p Your new password has been saved.

    router-link(to='/') 
      el-button(type='text') Back to login
</template>

<script>
import api from '../api'
import Cookies from 'js-cookie'

export default {
  name: 'ResetPassword',
  data () {
    let checkPasswordsMatch = (rule, value, callback) => {
      // check that password confirmation matches password
      if (this.form.password && this.form.password.length > 0 && value !== this.form.password) {
        callback(new Error('Passwords don\'t match'))
      } else {
        callback()
      }
    }

    return {
      loading: false,
      submitted: false,
      form: {
        email: null,
        reset_code: null,
        password: null,
        password_confirmation: null
      },
      rules: {
        password: [
          { required: true, message: 'Enter a password', trigger: 'submit' },
          { min: 14, message: 'Use 14 or more characters', trigger: 'blur' }
        ],
        password_confirmation: [
          { validator: checkPasswordsMatch, trigger: 'submit' }
        ]
      }
    }
  },
  methods: {
    resetPassword (e) {
      e.preventDefault()
      this.$refs.resetPasswordForm.validate((valid) => {
        if (valid) {
          this.loading = true
          api.post('reset/confirm', this.form).then((res) => {
            this.loading = false
            this.submitted = true
          }).catch(_ => {
            this.loading = false
            this.$message.error('Something has gone wrong. Contact proton.ai if this problem persists.')
          })
        }
      })
    }
  },
  mounted () {
    const resetSlug = this.$route.query.reset_code
    const [resetCode, company] = resetSlug.split(':')

    this.form.email = this.$route.query.email
    this.form.reset_code = resetCode

    if (company) {
      Cookies.set('company', company, { path: '', domain: '.proton.ai' })
    }
  }
}
</script>

<style lang='scss'>
@import '../scss/common';
</style>